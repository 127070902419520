<template>
  <form @submit.prevent="handleSubmit">
    <form-errors-component :errors="errors"></form-errors-component>

    <div class="field">
      <label class="label">Select coin pair to watch</label>
      <model-select :options="watchingOptions" v-model="selectedWatching" placeholder="Search for coin"></model-select>
    </div>
    <div class="field is-grouped">
      <div class="control">
        <button class="button is-link" type="submit">Watch coin pair</button>
      </div>
    </div>
  </form>
</template>

<script>
import { ModelSelect } from 'vue-search-select'
import FormErrorsComponent from 'form-errors-component'
import axios from 'axios'

const token = document.getElementsByName('csrf-token')[0].getAttribute('content')
axios.defaults.headers.common['X-CSRF-Token'] = token
axios.defaults.headers.common['Accept'] = 'application/json'

export default {
  data: function () {
    return {
      selectedWatching: null,
      watchingOptions: [],
      errors: [],
    }
  },
  components: { ModelSelect, FormErrorsComponent },
  created() {
    axios
      .get('/watchings')
      .then(response => response.data)
      .then(data => {
        this.watchingOptions = data
      })
      .catch(error => {
        console.log('error', error)
      })
  },
  methods: {
    handleSubmit() {
      this.errors = []
      axios
        .post('/watchlist', {
          watching_id: this.selectedWatching
        })
        .then(_ => {
          Turbolinks.visit('/watchlist')
        })
        .catch(error => {
          console.log('error', error)
          this.errors = error.response.data
        })
    }
  }
}
</script>

<style scoped>
</style>
