<template>
    <div v-if="errors.length" class="notification is-danger">
        <ul>
            <li v-for="error in errors">{{ error }}</li>
        </ul>
    </div>
</template>

<script>
export default {
  props: ['errors'],
  data() {
    return {
        value: ''
    }
  }
}
</script>

<style scoped>
</style>
